import ViewerTeam from '../team/ViewerTeam';
import User from './User';
import {TokenViewer} from '../core/auth/AuthProvider';
import Industry from '../industry/data/Industry';

class VIEWER_HAS_NO_TEAMS extends Error {
    constructor(message?: string) {
        super(message);
        this.name = 'VIEWER_HAS_NO_TEAMS';
    }
}

export default class Viewer {
    currentTeamId: string;
    id: string;
    teams: ViewerTeam[];
    user: User;
    forecastCutoff: string;
    forecastCutoffDigital: string;
    maxDate: string;

    // these are private because these properties only exist within client-main, and we use this
    // class in other packages as well
    private _requesterTeamHasNonTrialName?: boolean;
    private _requesterTeamHasAdCreativeIndustryIds?: boolean;
    private _requesterTeamIsDisabled?: boolean;

    constructor(data: {
        id: string;
        user: User;
        teams: ViewerTeam[];
        currentTeamId: string;
        forecastCutoff: string;
        forecastCutoffDigital: string;
        maxDate: string;
        requesterTeamHasNonTrialName?: boolean;
        requesterTeamHasAdCreativeIndustryIds?: boolean;
        requesterTeamIsDisabled?: boolean;
    }) {
        this.currentTeamId = data.currentTeamId;
        this.id = data.id;
        this.teams = data.teams;
        this.user = data.user;
        this.forecastCutoff = data.forecastCutoff;
        this.forecastCutoffDigital = data.forecastCutoffDigital;
        this.maxDate = data.maxDate;
        this._requesterTeamHasNonTrialName = data.requesterTeamHasNonTrialName;
        this._requesterTeamHasAdCreativeIndustryIds = data.requesterTeamHasAdCreativeIndustryIds;
        this._requesterTeamIsDisabled = data.requesterTeamIsDisabled;
    }

    static tokenHasClassic(tokenViewer: TokenViewer) {
        return tokenViewer.teamIds?.length > 0;
    }

    get requesterTeamHasNonTrialName() {
        if (typeof this._requesterTeamHasNonTrialName !== 'undefined')
            return this._requesterTeamHasNonTrialName;
        throw new Error('This viewer property does not exist');
    }

    get requesterTeamHasAdCreativeIndustryIds() {
        if (typeof this._requesterTeamHasAdCreativeIndustryIds !== 'undefined')
            return this._requesterTeamHasAdCreativeIndustryIds;
        throw new Error('This viewer property does not exist');
    }

    get requesterTeamIsDisabled() {
        if (typeof this._requesterTeamIsDisabled !== 'undefined')
            return this._requesterTeamIsDisabled;
        throw new Error('This viewer property does not exist');
    }

    get currentTeam() {
        const team = this.teams.find((ii) => ii.id === this.currentTeamId) || this.teams[0];
        if (!team) throw new VIEWER_HAS_NO_TEAMS();
        return team;
    }

    get hasClassic() {
        return this.teams.length > 0;
    }

    /** Takes an industry list, and returns the same list with accurate `hasAccess` getter */
    mapIndustriesWithMediaValueAccess(industryList: Industry[]): Industry[] {
        const mediaValueProduct = this.currentTeam.productMap.Australia?.MediaValue;
        if (!mediaValueProduct) return [];
        return industryList.map(
            ({id, label}) =>
                new Industry({
                    id,
                    name: label,
                    hasAccess: (mediaValueProduct.industryIds || []).includes(id)
                })
        );
    }
}

import TeamMember from '../team/TeamMember';
import {TeamProductStatus, ProductType} from '../team/Team';

export default class User {
    createdAt: string;
    firstname: string;
    id: string;
    lastname: string;
    memberships: Array<TeamMember>;
    name: string;
    phone: string | null;
    registrationStatus: string | null;
    status: {
        australianMediaValue: TeamProductStatus;
        australianCreative: TeamProductStatus;
        newZealandCreative: TeamProductStatus;
        adminProducts: ProductType[];
    };
    tier: number | null;
    updatedAt: string;
    username: string;
    hasDataIngestAccess?: boolean;

    constructor(input: Partial<User>) {
        for (const key in input) {
            this[key] = input[key];
        }
    }

    get email() {
        return this.username;
    }

    get adminItemTo() {
        return `/user-admin/user/${this.id}`;
    }

    get fullname() {
        return `${this.firstname} ${this.lastname}`;
    }
}
